import React, { useEffect, useState } from "react";
import { onValue, limitToLast, orderByChild, query } from "firebase/database";

import { turfersRef } from "../../Services/FirebaseDB";
import { FirebaseUserData } from "../../Types/UserData";
import TopTurfersChart, { TurfersMap } from "./TopTurfersChart";

const INIT_LIMIT = 10;
const LIMIT_STEP = 20;
const MAX_LIMIT = 100;

interface TopTurfersConfig {
  dataKey: keyof FirebaseUserData;
  footer?: React.ReactNode;
  name: string;
  title: string;
}

export function createTopTurfersComponent({
  dataKey,
  footer,
  name,
  title,
}: TopTurfersConfig) {
  const TopTurfersComponent: React.FC = () => {
    const [turfers, setTurfers] = useState<FirebaseUserData[]>([]);
    const [limit, setLimit] = useState(INIT_LIMIT);

    const showMore = () => {
      setLimit((prevLimit) => Math.min(prevLimit + LIMIT_STEP, MAX_LIMIT));
    };

    useEffect(() => {
      const turfersTopRef = query(
        turfersRef,
        orderByChild(dataKey as string),
        limitToLast(limit)
      );

      const unsub = onValue(turfersTopRef, (snapshot) => {
        const data: TurfersMap = snapshot.val();
        if (!data) {
          setTurfers([]);
          return;
        }
        const sortedList = Object.values(data).sort((a, b) =>
          ((a[dataKey] as number) ?? 0) < ((b[dataKey] as number) ?? 0) ? 1 : -1
        );
        setTurfers(Object.values(sortedList));
      });

      return () => unsub();
    }, [limit]);

    return (
      <div>
        <TopTurfersChart
          dataKey={dataKey}
          footer={footer}
          limit={limit}
          maxLimit={MAX_LIMIT}
          name={name}
          onShowMore={showMore}
          title={title}
          turfers={turfers}
        />
      </div>
    );
  };

  return React.memo(TopTurfersComponent);
}
