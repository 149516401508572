import React, { useState, useEffect, useMemo } from "react";
import { onValue, query, orderByKey, limitToLast } from "firebase/database";
import { ResponsiveLine } from "@nivo/line";
import { Form, Grid } from "tabler-react";
import dayjs from "dayjs";

import { medalsStatsRef, medalsRareStatsRef } from "../../Services/FirebaseDB";
import { COMMON_MEDALS_HOLDERS_LIMIT } from "../../Lib/Medals";
import { useTheme } from "../../Hooks/useTheme";
import { DarkTheme, LightTheme } from "../../Lib/ChartTheme";

type MedalData = { primary: Date; secondary: number };

const DailyMedals: React.FC = () => {
  const [selectedRange, setSelectedRange] = useState(30);
  const [medalsData, setMedalsData] = useState<MedalData[]>([]);
  const [medalsRareData, setMedalsRareData] = useState<MedalData[]>([]);

  const { theme } = useTheme();

  const handleRangeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRange(Number(event.target.value));
  };

  useEffect(() => {
    const medalsQuery = query(
      medalsStatsRef,
      orderByKey(),
      limitToLast(selectedRange)
    );
    const rareMedalsQuery = query(
      medalsRareStatsRef,
      orderByKey(),
      limitToLast(selectedRange)
    );

    const unsubMedals = onValue(medalsQuery, (snapshot) => {
      const data = snapshot.val() || {};
      const parsed = Object.entries<number>(data).map(([date, value]) => ({
        primary: dayjs(date, "YYYY-MM-DD").toDate(),
        secondary: value,
      }));
      setMedalsData(parsed);
    });

    const unsubRare = onValue(rareMedalsQuery, (snapshot) => {
      const data = snapshot.val() || {};
      const parsed = Object.entries<number>(data).map(([date, value]) => ({
        primary: dayjs(date, "YYYY-MM-DD").toDate(),
        secondary: value,
      }));
      setMedalsRareData(parsed);
    });

    return () => {
      unsubMedals();
      unsubRare();
    };
  }, [selectedRange]);

  const chartData = useMemo(
    () => [
      {
        id: "Rare Medals*",
        data: medalsRareData.map((d) => ({
          x: d.primary,
          y: d.secondary,
        })),
      },
      {
        id: "All Medals",
        data: medalsData.map((d) => ({
          x: d.primary,
          y: d.secondary,
        })),
      },
    ],
    [medalsData, medalsRareData]
  );

  return (
    <div>
      <Grid.Row className="flex-grow">
        <Grid.Col sm={9}>
          <h1>Medals per day</h1>
        </Grid.Col>
        <Grid.Col sm={3} className="text-right">
          <Form.Select onChange={handleRangeChange} value={selectedRange}>
            <option value="30">Last 30 days</option>
            <option value="60">Last 60 days</option>
            <option value="180">Last 180 days</option>
          </Form.Select>
        </Grid.Col>
      </Grid.Row>
      <div className="chart-container">
        <ResponsiveLine
          theme={theme === "dark" ? DarkTheme : LightTheme}
          data={chartData}
          margin={{ top: 20, right: 10, bottom: 50, left: 40 }}
          xScale={{ type: "time" }}
          yScale={{
            type: "linear",
            min: 0,
            max: "auto",
          }}
          enableGridX={false}
          axisBottom={{
            tickValues: `every ${(selectedRange / 15).toFixed(0)} days`,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -40,
            legend: "",
            legendOffset: 36,
            legendPosition: "middle",
            format: "%d.%m",
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Count",
            legendOffset: -35,
            legendPosition: "middle",
          }}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          enableSlices="x"
          legends={[
            {
              anchor: "top-right",
              direction: "column",
              translateY: -5,
              itemWidth: 80,
              itemHeight: 20,
              itemTextColor: "#999",
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
            },
          ]}
        />
      </div>
      <div className="mt-5 small-font-size">
        <p>
          <b>*</b> Rare medals are those that had less than{" "}
          {COMMON_MEDALS_HOLDERS_LIMIT} total holders when the medal was
          awarded.
        </p>
      </div>
    </div>
  );
};

export default DailyMedals;
