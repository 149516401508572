import firebaseApp from "./Firebase";

import { getDatabase, ref } from "firebase/database";

export const db = getDatabase(firebaseApp);

export const countriesRef = ref(db, "countries");
export const dailyRef = ref(db, "daily");
export const medalsRef = ref(db, "medals");
export const medalsFeedRef = ref(db, "feeds/medals");
export const medalsRareFeedRef = ref(db, "feeds/medalsRare");
export const medalsStatsRef = ref(db, "stats/medals");
export const medalsRareStatsRef = ref(db, "stats/medalsRare");
export const monumentZonesRef = ref(db, "zonesByType/Monument");
export const regionsRef = ref(db, "regions");
export const supportersRef = ref(db, "supporters");
export const turfersRef = ref(db, "turfers");
