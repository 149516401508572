import type { Theme } from "@nivo/core";
export const DarkTheme: Partial<Theme> = {
  crosshair: {
    line: {
      stroke: "#999",
    },
  },
  text: {
    fill: "#eee",
  },
  tooltip: {
    container: {
      background: "#333",
    },
  },
};

export const LightTheme: Partial<Theme> = {};
