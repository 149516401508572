import { createTopTurfersComponent } from "./TopTurfersComponent";

const TopTurfersBonanza = createTopTurfersComponent({
  dataKey: "bonanzaTotal",
  footer: "* Number of Bonanza Participant or Bonanza podium medals",
  name: "Bonanzas",
  title: "Bonanza Attendances",
});

export default TopTurfersBonanza;
