import React from "react";

import { GetAmountAttributes } from "../Lib/SupporterUtils";
import { useSupporters } from "../Hooks/useSupporters";
import { Link } from "react-router-dom";

const SupportersFooter: React.FC = () => {
  const supporters = useSupporters();

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col col-lg-12">
            <div className="row">
              <div className="col col-6 col-md-3">
                <ul className="list-unstyled mb-0">
                  <li className="">
                    <Link to="/about#Contribute">Supporters:</Link>
                  </li>
                </ul>
              </div>

              {supporters.map((supporter) => {
                const { className, title } = GetAmountAttributes(
                  supporter.amount
                );
                return (
                  <div className="col col-6 col-md-3" key={supporter.name}>
                    <Link to={`/profile/${supporter.name}`}>
                      {`${supporter.name}`}
                    </Link>
                    <span
                      className={className}
                      title={title}
                    >{` ${supporter.amount}`}</span>
                  </div>
                );
              })}
              <div className="col col-6 col-md-3">
                <a className="text-red" href="/about#Contribute">
                  Your name here?
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportersFooter;
