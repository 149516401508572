import { createTopTurfersComponent } from "./TopTurfersComponent";

const TopTurfersEvent = createTopTurfersComponent({
  dataKey: "eventorTotal",
  footer: "* Number of Eventor medals",
  name: "Events",
  title: "Event Attendances",
});

export default TopTurfersEvent;
