import React from "react";
import { Link } from "react-router-dom";

const Changelog: React.FC = () => {
  return (
    <div>
      <h3 id="Changelog">Changelog</h3>
      <ul>
        <li>
          <strong>20th of March, 2025</strong>: Added top Bonanza and Event
          charts to the <Link to="/top">Top page</Link>.
        </li>
        <li>
          <strong>19th of Feb, 2025</strong>: Added a new{" "}
          <Link to="/stats">stats page</Link> with a chart showing the number of
          awarded medals per day.
        </li>
        <li>
          <strong>6th of Feb, 2025</strong>: Added a new{" "}
          <Link to="/top">top list</Link>: Number of areas visisted
        </li>
        <li>
          <strong>5th of Feb, 2025</strong>: Added a new{" "}
          <Link to="/top">top list</Link>: Number of regions visisted
        </li>
        <li>
          <strong>20th of Sept, 2024</strong>: Added region count to the
          Explorer medal information boxes + show current Region Lord for a
          turfer's region on the profile page.
        </li>
        <li>
          <strong>15th of Sept, 2024</strong>: Added the new Monument medals.
          Please note that for most turfers this will probably lower their
          overall TM score.
        </li>
        <li>
          <strong>8th of June, 2024</strong>: Add{" "}
          <Link to="/daily">Top Daily</Link>.
        </li>
        <li>
          <strong>5th of June, 2024</strong>: Add Daily numbers and progress
          thanks to turfbridge.com/rip.php
        </li>
        <li>
          <strong>3rd of June, 2024</strong>: Display and use "Total Holders"
          (current holders + those that also qualify for it by having a higher
          graded medal) when calculating the easiest and most obscure medals a
          turfer has.
        </li>
        <li>
          Changelog before 3rd of June, 2024:{" "}
          <a href="https://forum.turfgame.com/viewtopic.php?t=17464">
            https://forum.turfgame.com/viewtopic.php?t=17464
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Changelog;
