import React, { useEffect } from "react";
import { Card } from "tabler-react";

import DailyMedals from "../Components/Stats/DailyMedals";

const Top: React.FC = () => {
  useEffect(() => {
    document.title = `Stats - Turf Medals`;
  }, []);

  return (
    <div>
      <Card className="p-4">
        <DailyMedals />
      </Card>
    </div>
  );
};

export default React.memo(Top);
