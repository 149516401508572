import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Table } from "tabler-react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "../../Hooks/useTheme";
import { DarkTheme, LightTheme } from "../../Lib/ChartTheme";
import Username from "../Username";
import { FirebaseUserData } from "../../Types/UserData";
import { useSupporters } from "../../Hooks/useSupporters";

const CHART_COLOR = "#467fcf"; // bg-blue
const TOP_CHART_COUNT = 10;
const TICK_SIZE = 5;

export type TurfersMap = Record<string, FirebaseUserData>;

type TopTurfersProps = {
  dataKey: keyof FirebaseUserData; // The key in FirebaseUserData to use ("regionsVisited", "areasVisited", etc.)
  footer?: React.ReactNode; // Optional footer to display
  limit: number; // Current limit of items to show
  maxLimit: number; // Maximum limit that can be shown
  name: string;
  onShowMore: () => void; // Handler for "Show more" button
  title: string; // The title to display (e.g., "Regions Visited", "Areas Visited")
  turfers: FirebaseUserData[]; // The list of turfers to display
};

const TopTurfersChart: React.FC<TopTurfersProps> = ({
  dataKey,
  footer,
  limit,
  maxLimit,
  name,
  onShowMore,
  title,
  turfers,
}) => {
  const supporters = useSupporters();
  const { theme } = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);

  // Set up intersection observer to detect when chart is in viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          // Once visible, disconnect the observer
          observer.disconnect();
        }
      },
      { threshold: 0.7 } // Trigger when at least 10% of the element is visible
    );

    if (chartRef.current) {
      observer.observe(chartRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  // Prepare chart data - top N turfers
  const chartData = isVisible
    ? turfers
        .slice(0, TOP_CHART_COUNT)
        .map((turfer, index) => ({
          name: turfer.name,
          value: (turfer[dataKey] as number) || 0,
          rank: index + 1,
          displayName: `#${index + 1} ${turfer.name}`, // Add a formatted display name with rank
        }))
        // Reverse the array to show #1 at the top
        .reverse()
    : [];

  return (
    <div>
      <h3 className="mb-4">
        Top Turfers - <em>{title}</em>
      </h3>

      <div
        className="chart-container"
        ref={chartRef}
        style={{ height: "400px" }}
      >
        {isVisible ? (
          <ResponsiveBar
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: TICK_SIZE,
              tickPadding: 5,
              tickRotation: 0,
              legend: title,
              legendPosition: "middle",
              legendOffset: 35,
            }}
            axisLeft={{
              tickSize: TICK_SIZE,
              tickPadding: 5,
              tickRotation: 0,
              renderTick: (props) => {
                const { value, x, y, opacity, textX, textY } = props;
                // Extract username from the displayName (removes "#1 " prefix)
                const username = value.split(" ").slice(1).join(" ");

                return (
                  <g transform={`translate(${x},${y})`} style={{ opacity }}>
                    <line
                      x1={0}
                      x2={-TICK_SIZE}
                      y1={0}
                      y2={0}
                      stroke="#888"
                      strokeWidth={1}
                    />
                    <Link to={`/profile/${username}`}>
                      <text
                        x={textX}
                        y={textY}
                        textAnchor="end"
                        dominantBaseline="central"
                        style={{
                          fill: theme === "dark" ? "#fff" : "#333",
                          fontSize: 11,
                        }}
                      >
                        {value}
                      </text>
                    </Link>
                  </g>
                );
              },
            }}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            colors={[CHART_COLOR]}
            data={chartData}
            indexBy="displayName"
            indexScale={{ type: "band", round: true }}
            keys={["value"]}
            labelSkipHeight={12}
            labelSkipWidth={12}
            labelTextColor="white"
            layout="horizontal"
            margin={{ top: 10, right: 20, bottom: 50, left: 120 }}
            padding={0.3}
            theme={theme === "dark" ? DarkTheme : LightTheme}
            tooltip={({ data, value }) => (
              <div
                style={{
                  padding: "8px 12px",
                  background: theme === "dark" ? "#333" : "#fff",
                  color: theme === "dark" ? "#fff" : "#333",
                  border: `1px solid ${theme === "dark" ? "#555" : "#ddd"}`,
                  borderRadius: "4px",
                }}
              >
                <strong>
                  #{data.rank} {data.name}
                </strong>
                <br />
                {title}: <strong>{value}</strong>
              </div>
            )}
            valueScale={{ type: "linear" }}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <p className="text-muted">Scroll to load chart...</p>
          </div>
        )}
      </div>

      {limit > TOP_CHART_COUNT && (
        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter"
        >
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>#</Table.ColHeader>
              <Table.ColHeader>Name</Table.ColHeader>
              <Table.ColHeader>
                <strong>{name}</strong>
              </Table.ColHeader>
              <Table.ColHeader>TM Score</Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {turfers.slice(TOP_CHART_COUNT).map((t, index) => (
              <Table.Row key={t.name}>
                <Table.Col>{TOP_CHART_COUNT + index + 1}</Table.Col>
                <Table.Col>
                  <Username username={t.name} supporters={supporters} />
                </Table.Col>
                <Table.Col>
                  <strong>{(t[dataKey] as number) ?? "-"}</strong>
                </Table.Col>
                <Table.Col>
                  {t.totalScore
                    ? `${Math.round(t.totalScore * 100 * 100) / 100}%`
                    : "-"}
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
      {footer && <div className="small-font-size">{footer}</div>}
      {limit < maxLimit && (
        <div className="m-4">
          <Button onClick={onShowMore}>Show more</Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(TopTurfersChart);
