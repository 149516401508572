import { get, ref } from "firebase/database";

import UserData, { FirebaseUserData } from "../Types/UserData";
import { db } from "./FirebaseDB";

const BASE_URL =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "https://api.turfgame.com"
    : "https://apiturf.turfmedals.com";

const PORTALEN_BASE_URL = "https://apiturfportalen.turfmedals.com";

export const fetchTurfgameUser = async (name: string): Promise<UserData> => {
  const response = await fetch(`${BASE_URL}/private/users`, {
    body: JSON.stringify([{ name: name?.replace(/ /g, "") }]),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user data");
  }

  const [user] = await response.json();

  if (!user) {
    throw new Error("User not found");
  }

  const timeout = () =>
    new Promise<null>((resolve) => setTimeout(() => resolve(null), 1500));

  const rankRef = ref(db, `ranks/${user.id}`);
  const rankSnapshot = await Promise.race([get(rankRef), timeout()]).catch(
    () => null
  );
  const rank = rankSnapshot ? rankSnapshot.val() : null;

  const turferRef = ref(db, `turfers/${user.id}`);
  const turferSnapshot = await Promise.race([get(turferRef), timeout()]).catch(
    () => null
  );
  const turfer: FirebaseUserData | null = turferSnapshot
    ? turferSnapshot.val()
    : null;

  // Augment the user data with firebase data
  return {
    ...user,
    TMRank: rank || null,
    daily: turfer?.daily,
    areasVisited: turfer?.areasVisited,
    bonanzaPodiumTotal: turfer?.bonanzaPodiumTotal,
    bonanzaTotal: turfer?.bonanzaTotal,
    event24Total: turfer?.event24Total,
    eventorTotal: turfer?.eventorTotal,
    regionsVisited: turfer?.regionsVisited,
  };
};

export const fetchTurfportalenUser = async (name: string): Promise<any> => {
  const response = await fetch(
    `${PORTALEN_BASE_URL}/api/player/${encodeURIComponent(
      name.replace(/ /g, "")
    )}`
  );

  if (!response.ok) {
    console.warn(
      "Could not get from Turfportalen, but resolve user from turfgame"
    );
    return null;
  }

  const [portalenUser] = await response.json();

  return portalenUser;
};
