import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "tabler-react";
import { onValue, limitToLast, query, orderByKey } from "firebase/database";

import MedalItem from "../Components/MedalItem";
import {
  COMMON_MEDALS_HOLDERS_LIMIT,
  GetAllMedals,
  GetMedalName,
} from "../Lib/Medals";
import { medalsFeedRef, medalsRareFeedRef } from "../Services/FirebaseDB";
import { MedalFeedData } from "../Types/MedalTypes";
import { useSupporters } from "../Hooks/useSupporters";
import Username from "../Components/Username";
import dayjs from "../Lib/DateUtils";

const ALL_MEDALS = GetAllMedals();
const LIMIT_STEP = 20;
const MAX_LIMIT = 160;

type TurfersMap = Record<string, MedalFeedData>;
type MedalFeedStateData = MedalFeedData & { key: string };

const Top: React.FC = () => {
  const [hideCommon, setHideCommon] = useState(false);
  const [medals, setMedals] = useState<MedalFeedStateData[]>([]);
  const [limit, setLimit] = useState(LIMIT_STEP);
  const [, setNow] = useState(dayjs());
  const supporters = useSupporters();

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(dayjs());
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const showMore = () => {
    setLimit((prevLimit) => prevLimit + LIMIT_STEP); // Increase limit by 20 (or any other number)
  };

  useEffect(() => {
    document.title = "Medals feed";

    const medalsLatestRef = query(
      hideCommon ? medalsRareFeedRef : medalsFeedRef,
      orderByKey(),
      limitToLast(limit)
    );

    const unsub = onValue(medalsLatestRef, (snapshot) => {
      const data: TurfersMap = snapshot.val();
      if (!data) {
        setMedals([]);
        return;
      }
      const medalsList = Object.entries(data)
        .map(([key, medal]) => ({
          ...medal,
          key,
        }))
        .reverse();
      setMedals(Object.values(medalsList));
    });

    return () => unsub();
  }, [hideCommon, limit]);

  return (
    <div>
      <Card className="p-4">
        <div>
          <h3>
            Medals feed <i className="fe fe-loader icon-spin pull-right"></i>
          </h3>

          <div>
            <Form.Checkbox
              checked={hideCommon}
              label={
                <span>
                  Hide common medals <a href="#common">(*)</a>
                </span>
              }
              name="hideCommon"
              onChange={() => {
                setHideCommon(!hideCommon);
              }}
            />
          </div>

          <Table
            cards={true}
            striped={true}
            responsive={true}
            className="table-vcenter table-sm small-font-size"
          >
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>Name</Table.ColHeader>
                <Table.ColHeader>Medal</Table.ColHeader>
                <Table.ColHeader>Turfer</Table.ColHeader>
                <Table.ColHeader>Time</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {medals.map((m) => {
                const medal = ALL_MEDALS[m.medal];
                if (!medal) return null;

                return (
                  <Table.Row key={m.key}>
                    <Table.Col>{GetMedalName(medal)}</Table.Col>
                    <Table.Col>
                      <MedalItem medal={medal} size="small" />
                    </Table.Col>
                    <Table.Col>
                      <Username
                        country={m.userCountry}
                        showFlag
                        supporters={supporters}
                        username={m.name}
                      />
                    </Table.Col>
                    <Table.Col>
                      <span title={dayjs(m.time).format("LLL")}>
                        {dayjs(m.time).fromNow()}
                      </span>
                    </Table.Col>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {limit < MAX_LIMIT && (
            <div className="m-4">
              <Button onClick={showMore}>Show more</Button>
            </div>
          )}
        </div>
        <div id="common" className="mt-5 muted">
          <p>
            <b>*</b> Common medals are those that have more than{" "}
            {COMMON_MEDALS_HOLDERS_LIMIT} total holders, and thus considered
            somewhat easily achieved by most turfers. They can be hidden to make
            the list more interesting.
          </p>
        </div>
      </Card>
    </div>
  );
};

export default React.memo(Top);
